import { UserInterface } from "@kanvas/client-js";
import CookiesUniversal from "cookie-universal";
import { sdk, sdkv1 } from "@models/api";
import { invalidate } from "@models/api/cache";
import useAmplitude from "@models/interactions/use-amplitude";

const cookies = CookiesUniversal();

export function logout(user: UserInterface) {
  const { operations } = useAmplitude();

  invalidate(cookies.get("token"), user.id).then(() => {
    operations.send("logout", {
      id: user.id,
    });

    cookies.removeAll();

    cookies.remove("refresh_token", {
      path: "/",
      domain: window.location.hostname,
      sameSite: true,
    });

    cookies.remove("access_token", {
      path: "/",
      domain: window.location.hostname,
      sameSite: true,
    });

    cookies.remove("token", {
      path: "/",
      domain: window.location.hostname,
      sameSite: true,
    });

    operations.setUserId(null);
    sdk.tokenProvider.removeTokens();
    sdkv1.tokenProvider.removeTokens();

    setTimeout(() => {
      window.location.href = `/`;
    }, 200);
  });
}
