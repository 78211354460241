import AvatarImg from "public/img/defaults/avatar.png";
import Icon from "@components/atoms/icon";
import { UserAtom } from "@models/state";
import { useRouter } from "next/router";
import { useAtomValue } from "jotai";
import Link from "next/link";
import { useMemo } from "react";
import Avatar from "@components/atoms/avatar"

interface Props {
  onClick?: () => void;
}

export function useNavbarLogged(props: Props) {
  const user = useAtomValue(UserAtom);
  const route = useRouter();

  const notifications = useMemo(
    // @ts-ignore
    () => user?.new_notification,
    [user, route.asPath]
  );

  const showNotifications = useMemo(
    // @ts-ignore
    () => user?.new_notification >= 1,
    [user, route.asPath]
  );

  return {
    models: {
      user,
      notifications,
      showNotifications,
    },
    operations: {},
  };
}

export default function NavbarLoggedMobile(props: Props) {
  const { models } = useNavbarLogged(props);

  return (
    <span className="flex gap-6">
      <Link
        href={`/search/""/memos`}
        className="flex justify-center items-center hover:bg-white/10"
      >
        <Icon.Search />
      </Link>

      <Link
        href={`/inbox/all-notifications`}
        className="flex justify-center items-center hover:bg-white/10"
      >
        {models.showNotifications && (
          <span className="absolute rounded-full bg-memod-green-base px-1 text-xs">
            {models.notifications}
          </span>
        )}

        <Icon.Bell />
      </Link>

      <button onClick={props.onClick}>
        <Avatar src={models.user?.photo?.url ?? AvatarImg as any} size={32} />
      </button>
    </span>
  );
}
