import button from "@components/atoms/button";
import Input from "@components/atoms/input";
import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import { useRouter } from "next/router";
import {
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import RecentSearch from "../recent-search";
import { useClickOutside } from "@models/interactions/use-click-outside";
import { sdkv1 } from "@composer/models/api";
import { useDebounce } from "usehooks-ts";
import useAmplitude from "@models/interactions/use-amplitude";
import { useAtomValue } from "jotai";
import { UserAtom } from "@models/state";
import classNames from "classnames";
import union from "lodash/union";
import flatten from "lodash/flatten";

interface Props {
  setSearch: (value: string) => void;
  onEnter?: () => void;
  search: string;
  index?: string;
  autocomplete?: boolean;
  inputClassName?: string;
}

const MAX_ITEMS = 10;

export function useInputSearch(props: Props) {
  const [recentSearch, setRecentSearch] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showRecents, setShowRecents] = useState<boolean>(false);
  const debouncedValue = useDebounce<string>(props.search, 500);
  const { operations } = useAmplitude();
  const user = useAtomValue(UserAtom);
  const router = useRouter();

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && props.search.length > 0) {
      setRecentSearch((prev) => [
        ...[props.search as string],
        ...(prev !== null ? prev.filter(searches => searches !== props.search) : []),
      ]);
      setShowRecents(false);
      operations.send(user ? "Clicked Component" : "Unknown User Clicked Component",{
        name: `track-keywords-search`,
        title: `Track keywords on search`,
        keyword: props.search,
        ...(user && { user_id: operations.getUserId }),
        ...(!user && { unknown_user_id: operations.getUserId }),
        path: router.asPath,
        click_at: Date.now()
      });
      props.onEnter?.();
      router.push(
        `/search/${props.search}/${props.index ? props.index : "memos"}`
      );
    }
  }

  function onChange(event: string) {
    props.setSearch(event);
  }

  function handleClean() {
    props.setSearch("");
    setShowRecents(false);
  }

  async function searchHistoryRecommendation(search: string) {
    try {
      const promises = [];
      promises.push(searchSuggestion(search, "memos"));
      promises.push(searchSuggestion(search, "topics"));
      promises.push(searchSuggestion(search, "users"));
      promises.push(searchSuggestion(search, "boards"));
      const suggestions = flatten(await Promise.all(promises));
      const uniques = union(suggestions.filter((item) => item?.suggestion !== '').map((item) => item?.suggestion));
      setSuggestions(uniques);
    } catch(e) {
      console.log(e);
    }
  }

  async function searchSuggestion(search: string, index: string) {
    if (!search) return [];

    try {
      const { data } = await sdkv1.http.http.get(`/search/${search}/suggestion?index=${index}`);
      const results = !Array.isArray(data.results.documents) ? Object.values(data.results.documents) : data.results.documents;
      return results;
    } catch(e) {
      throw new Error(`searchSuggestion error: ${e}`);
    }
  }

  function redirectOnClick(search: string) {
    if (search.length!) {
      setRecentSearch((prev) => [
        ...[search as string],
        ...(prev !== null ? prev.filter(searches => searches !== search) : []),
      ]);
      setShowRecents(false);
      router.push(`/search/${search}/${props.index ? props.index : "memos"}`);
    }
  }

  function onClick(search: string) {
    props.setSearch(search);
    setShowRecents(false);
    redirectOnClick(search);
  }

  useEffect(() => {
    if (props.search.length) {
      searchHistoryRecommendation(props.search);
    }
  }, [debouncedValue]);

  useEffect(() => {
    const storedList = localStorage.getItem("search_recents");

    if (storedList) {
      const parsedList = JSON.parse(storedList);
      setRecentSearch(parsedList);
    }

    return () => {
      setRecentSearch([]);
    };
  }, []);

  useEffect(() => {
    if (props.search.length!) {
      searchHistoryRecommendation("");
    }
  }, []);

  useEffect(() => {
    if (recentSearch.length!) {
      localStorage.setItem("search_recents", JSON.stringify(recentSearch));
    }
  }, [recentSearch]);

  return {
    models: { onKeyDown, recentSearch, showRecents, suggestions },
    operations: {
      setShowRecents,
      setRecentSearch,
      onClick,
      handleClean,
      onChange,
    },
  };
}

export default function inputSearch(props: Props) {
  const { models, operations } = useInputSearch(props);

  const wrapperRef = useClickOutside(() => {
    operations.setShowRecents(false);
  });

  return (
    <span ref={wrapperRef} className="relative w-full gap-2">
      <Input
        type="text"
        value={props.search || ""}
        onChange={(e) => operations.onChange(e.target.value)}
        IconClassName="!self-center !pl-4"
        placeholder={translate("base.search-placeholder")}
        onKeyDown={(e) => models.onKeyDown(e)}
        Icon={<Icon.Search className="text-base-grey" />}
        button={
          <button.Icon
            onclick={() => operations.handleClean()}
            className="flex h-6 w-6 items-center justify-center rounded-full bg-composer-base hover:bg-memod-separator"
            Icon={<Icon.Close className="text-base-grey" />}
          />
        }
        toggleShowRecent={operations.setShowRecents}
        className={classNames(
          "!focus:outline-none !focus:ring-0 !focus:border-transparent !w-full border border-[#38383A] !bg-memod-button !text-base !text-[#EBEBF5]",
          props.inputClassName
        )}
      />

      {models.showRecents && props.autocomplete && (
        <RecentSearch
          search={props.search}
          searches={models.recentSearch}
          redirectOnClick={operations.onClick}
          setRecentSearch={operations.setRecentSearch}
          suggestions={models.suggestions}
        />
      )}
    </span>
  );
}
