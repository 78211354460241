import useAmplitude from "@models/interactions/use-amplitude";
import NavbarMenu from "@components/molecules/navbar-menu";
import AvatarImg from "public/img/defaults/avatar.png";
import Icon from "@components/atoms/icon";
import { UserAtom } from "@models/state";
import { useRouter } from "next/router";
import { translate } from "@locales";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import Link from "next/link";

interface Props {}

export function useNavbarLogged(props: Props) {
  const user = useAtomValue(UserAtom);
  const router = useRouter();
  const { operations: amplitude } = useAmplitude();

  const notifications = useMemo(
    // @ts-ignore
    () => user?.new_notification,
    [user, router.asPath]
  );

  const showNotifications = useMemo(
    // @ts-ignore
    () => user?.new_notification >= 1,
    [user, router.asPath]
  );

  const createClicked = () => {
    amplitude.send("Page Viewed", {
      viewed_at: Date.now(),
      page_name: "Create Memo",
      path: router.asPath,
      viewed_from: "N/A",
    });
  }

  return {
    models: {
      user,
      notifications,
      showNotifications,
    },
    operations: {
      createClicked,
    },
  };
}

export default function NavbarLogged(props: Props) {
  const { models, operations } = useNavbarLogged(props);

  return (
    <>
      <Link
        href={`/home/following`}
        className="mt-1 h-10 rounded-3xl px-2 !py-2 text-center hover:bg-white/10"
      >
        {translate("base.navbar.home")}
      </Link>

      <Link
        href={"/create"}
        className="mt-1 h-10 rounded-3xl px-2 !py-2 text-center hover:bg-white/10"
        onClick={operations.createClicked}
      >
        {translate("base.navbar.create")}
      </Link>

      <Link
        href={`/library`}
        className="mt-1 h-10 rounded-3xl px-2 !py-2 text-center hover:bg-white/10"
      >
        {translate("base.library")}
      </Link>

      <Link
        href={`/inbox/all-notifications`}
        className="mt-[4px] h-10 rounded-3xl px-2 !py-1 text-center hover:bg-white/10"
      >
        {models.showNotifications && (
          <span className="absolute rounded-full bg-memod-green-base px-1 text-xs">
            {models.notifications}
          </span>
        )}

        <Icon.Bell />
      </Link>

      <NavbarMenu
        photo={models.user?.photo?.url ?? AvatarImg}
        userName={models.user?.displayname!}
      />
    </>
  );
}
