import classNames from "classnames";
import Image from "next/image";
import CoverImage from "public/img/defaults/avatar.png";

interface Props {
  className?: string;
  square?: boolean;
  size?: number;
  src: string;
}

export default function Avatar(props: Props) {
  const { size = 48, square, className } = props;
  const styles = classNames(
    "w-fit h-fit bg-white shrink-0 flex-1 basis-0",
    {
      "rounded-full": !square,
      rounded: square,
    },
    className,
  );

  return (
    <Image
      alt=""
      width={size}
      height={size}
      src={props.src ?? CoverImage}
      className={styles}
      style={{
        minWidth: size,
        minHeight: size,
        maxWidth: size,
        maxHeight: size,
      }}
    />
  );
}
