import classNames from "classnames";
import { ReactNode, KeyboardEventHandler, FocusEvent, useMemo } from "react";
interface Props {
  value?: string;
  onChange?: (e: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  IconClassName?: string;
  buttonClassName?: string;
  button?: JSX.Element;
  placeholder?: string;
  className?: string;
  Icon?: ReactNode;
  active?: boolean;
  disable?: boolean;
  type?: string;
  toggleShowRecent?: (value: boolean) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

export function useInput(props: Props) {
  const buttonClassName = useMemo(() => {
    if (props.buttonClassName) return props.buttonClassName;

    return props.IconClassName;
  }, [props.buttonClassName, props.IconClassName]);

  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    props.onFocus?.();
    if (props.toggleShowRecent) {
      props.toggleShowRecent?.(true);
    }
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    props.onBlur?.();
  };

  return {
    models: { buttonClassName },
    operations: { onFocus, onBlur },
  };
}

export default function Input(props: Props) {
  const {
    type = "text",
    placeholder,
    className,
    Icon,
    onChange,
    button,
    value,
    disable,
  } = props;

  const { models, operations } = useInput(props);

  const container = classNames(
    "w-full h-[48px] flex flex-row px-[58px] py-0 gap-[10px] rounded-[30px] border border-memod-separator bg-memod-black items-center justify-center outline-none text-memod-separator text-[16px]",
    className
  );

  return (
    <div className="relative flex w-full flex-row items-center justify-end">
      <div
        className={classNames(
          "absolute inset-y-0 left-0 flex h-[48px] flex-col justify-center",
          props.IconClassName
        )}
      >
        {Icon}
      </div>
      <input
        onFocus={operations.onFocus}
        onBlur={operations.onBlur}
        onKeyDown={props.onKeyDown}
        onChange={onChange}
        className={container}
        placeholder={placeholder}
        type={type}
        value={value || ""}
        disabled={disable}
      />
      {button && (
        <div
          className={classNames(
            "absolute inset-y-0 right-2 flex h-[48px] flex-col justify-center",
            models.buttonClassName
          )}
        >
          {button}
        </div>
      )}
    </div>
  );
}
