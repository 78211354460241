import MenuArrow from "@components/atoms/icon/menu-arrow";
import { Popover, Transition } from "@headlessui/react";
import Avatar from "@components/atoms/avatar";
import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import { Fragment } from "react";
import Link from "next/link";
import { useAtomValue } from "jotai";
import { UserAtom } from "@models/state";
import { logout } from "@models/interactions/auth";
import { UserInterface } from "@kanvas/client-js";

interface Props {
  photo: any;
  userName: string;
}

export function useNavbarMenu() {
  const user = useAtomValue(UserAtom);

  const logOut = () => {
    logout(user as UserInterface);
  };

  return {
    operations: {
      logOut,
    },
  };
}

export default function NavbarMenu(props: Props) {
  const { operations } = useNavbarMenu();

  return (
    <Popover className="relative h-full flex-1">
      {({ open }) => (
        <>
          <Popover.Button
            style={{
              all: "unset",
              paddingLeft: "0.75rem",
              paddingRight: "0.75rem",
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
            }}
          >
            <div
              className={
                "cursor-pointer appearance-none !border-none  hover:scale-[.85] focus:!border-none active:!border-none"
              }
            >
              <Avatar src={props.photo} size={32} />
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/3 z-50 mt-1 flex w-[250px] -translate-x-3/4 transform flex-col items-center rounded-xl px-4 sm:px-0">
              <MenuArrow className="-mr-36 text-composer-active" />
              <ul className="flex w-full cursor-pointer flex-col items-start justify-start rounded-xl bg-composer-active text-lg font-normal">
                <Link
                  href={`/${props.userName}/memos`}
                  className="flex w-full flex-row justify-between rounded-t-xl px-5 py-4 text-left hover:bg-memod-bullet-hover"
                >
                  <span>{translate("base.profile")}</span>
                  <Icon.User />
                </Link>
                <Link
                  href={`/settings/edit`}
                  className="flex w-full flex-row justify-between px-5 py-4 text-left hover:bg-memod-bullet-hover"
                >
                  <span>{translate("base.settings")}</span>
                  <Icon.Settings />
                </Link>
                <Link
                  href={`/help`}
                  className="w-full px-5 py-4 text-left hover:bg-memod-bullet-hover"
                >
                  {translate("base.help-center")}
                </Link>
                <span
                  className="flex w-full flex-row justify-between rounded-b-xl px-5 py-4 hover:bg-memod-bullet-hover"
                  onClick={operations.logOut}
                >
                  <span>{translate("base.sign-out")}</span>
                  <Icon.SignOut />
                </span>
              </ul>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
