import Button from "@components/atoms/button";
import Icon from "@components/atoms/icon";
import InputSearch from "@components/molecules/input-search";
import NavbarLogged from "@components/molecules/navbar-logged";
import NavbarLoggedOut from "@components/molecules/navbar-logged-out";
import { UserAtom } from "@models/state";
import {
  AUTH_STATUS,
  AuthModalAtom,
} from "@models/state/atoms/auth-modal.atom";
import { UserLoggedAtom } from "@models/state/atoms/user-logged.atom";
import classNames from "classnames";
import { useAtomValue, useStore } from "jotai";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useNavbarMenu } from "@components/molecules/navbar-menu";
import useAmplitude from "@models/interactions/use-amplitude";
import NavbarLoggedMobile from "@components/molecules/navbar-logged-mobile";
import DrawerModal from "@components/molecules/drawer-modal";

interface Props {
  className?: string;
  hiddeBack?: boolean;
  search?: boolean;
  showHomeLinks?: boolean;
  autocomplete?: boolean;
  placement?: "right" | "left";
}

export function useCommonHeader() {
  const router = useRouter();
  const { operations } = useAmplitude();
  const logged = useAtomValue(UserLoggedAtom);
  const user = useAtomValue(UserAtom);
  const store = useStore();
  const [search, setSearch] = useState("");
  const [size, setSize] = useState(0);
  const isCurrentPath = (path: string) => router.asPath === path;
  const [drawer, setDrawer] = useState(false);

  const isHomePage = [
    "/",
    "/home",
    "/about-us",
    "/testimonials",
    "/creators",
  ].includes(router.asPath);

  const homeUrl = logged ? "/home/following" : "/";
  const back = () => window.history.go(-1);

  function resize() {
    setSize(window.innerWidth);
  }

  const signupAction = () => {
    if (["/creators"].includes(router.asPath)) {
      operations.send("SignUp Creators", {
        unknown_user_id: operations.getUserId(),
        opened_at: Date.now(),
      });
    }

    store.set(AuthModalAtom, {
      mode: AUTH_STATUS.SIGN_UP,
      open: true,
    });
  };

  useEffect(() => {
    const isClient = typeof window !== "undefined";

    if (isClient) {
      resize();
      window.addEventListener("resize", resize);

      return () => window.removeEventListener("resize", resize);
    }
  }, []);

  return {
    models: {
      user,
      logged,
      search,
      drawer,
      isHomePage,
      homeUrl,
      size,
    },
    operations: {
      back,
      store,
      setSearch,
      isCurrentPath,
      setDrawer,
      signupAction,
    },
  };
}

export default function CommonHeader(props: Props) {
  const { operations, models } = useCommonHeader();
  const {
    operations: { logOut },
  } = useNavbarMenu();

  return (
    <header
      className={classNames(
        "border-memod-separator bg-composer-bg fixed left-0 right-0 z-[50] mx-auto flex h-16 w-full max-w-[1440px] items-center justify-between border-b px-4 md:h-[72px] md:px-9 lg:px-8",
        props.className
      )}
    >
      <DrawerModal
        search={models.search}
        size={models.size}
        user={models.user}
        logOut={logOut}
        autocomplete={props.autocomplete}
        isHomePage={models.isHomePage}
        setDrawer={operations.setDrawer}
        setSearch={operations.setSearch}
        drawer={models.drawer}
      />

      <div
        className={classNames("flex items-center", {
          "xl:gap-10": props.hiddeBack,
          "xl:gap-8": !props.hiddeBack,
        })}
      >
        <div className="flex items-center gap-2.5">
          <Link href={models.homeUrl}>
            <Icon.Memod size={128} className="hidden xl:block" />
            <Icon.MiniMemod size={44} className="block xl:hidden" />
          </Link>

          <Button.Round
            Icon={<Icon.ArrowBack />}
            onclick={operations.back}
            className={classNames({
              hidden: props.hiddeBack,
            })}
          />
        </div>
        {props.showHomeLinks && (
          <div className="hidden text-xl leading-8 xl:flex">
            <ul className="text-memod-grey-secondary flex flex-row">
              <li
                className={classNames("px-4", {
                  "text-memod-green-base":
                    operations.isCurrentPath("/about-us"),
                })}
              >
                <Link href="/about-us" aria-current="page">
                  About
                </Link>
              </li>
              <li
                className={classNames(
                  "px-4",
                  {
                    "text-memod-green-base": operations.isCurrentPath("/creators"),
                    "hidden": operations.isCurrentPath("/ai-content-writer"),
                  }
                )}>
                <Link href="/creators" aria-current="page">
                  Start Creating!
                </Link>
              </li>
              <li className={classNames(
                "px-4",
                {
                  "hidden": operations.isCurrentPath("/ai-content-writer"),
                }
              )}>
                <a
                  href="https://memod.notion.site/memod/AI-Writing-Contest-Memo-d-95fad73ac7544a4599dec184b17d7c65"
                  target="_blank"
                  rel="nofollow"
                >
                  Memo Contest
                </a>
              </li>
              <li
                className={classNames(
                  "px-4",
                  {
                    "text-memod-green-base": operations.isCurrentPath("/ai-content-writer"),
                    "hidden": !operations.isCurrentPath("/ai-content-writer"),
                  }
                )}>
                <Link href="/ai-content-writer" aria-current="page">
                  AI Content Creator
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>

      <div
        className={classNames(
          "hidden grow px-8 xl:flex",
          {
            hidden: !props.search,
          },
          { "md:flex": models.logged }
        )}
      >
        {props.search && (
          <InputSearch
            autocomplete={true}
            search={models.search}
            setSearch={operations.setSearch}
          />
        )}
      </div>

      <div
        className={classNames(
          "bg-memod-button hidden rounded-full p-1 text-white xl:flex",
          { "justify-center gap-5 text-center md:flex": models.logged },
          { "gap-2": !models.logged }
        )}
      >
        {models.logged ? (
          <NavbarLogged />
        ) : (
          <NavbarLoggedOut
            onLogIn={() => {
              operations.store.set(AuthModalAtom, {
                mode: AUTH_STATUS.LOGIN,
                open: true,
              });
            }}
            onSignUp={operations.signupAction}
          />
        )}
      </div>

      <div
        className={classNames(
          "flex gap-2 text-sm leading-4 text-white xl:hidden"
        )}
      >
        <div
          className={classNames(
            "bg-memod-button hidden gap-2 rounded-full p-1",
            {
              "!flex": models.isHomePage && models.size < 1140,
            },
            { "md:hidden": models.logged }
          )}
        >
          <NavbarLoggedOut
            onLogIn={() => {
              operations.setDrawer(false);
              operations.store.set(AuthModalAtom, {
                mode: AUTH_STATUS.LOGIN,
                open: true,
              });
            }}
            onSignUp={operations.signupAction}
          />
        </div>
        <div
          className={classNames("flex items-center gap-3 xl:hidden", {
            "!hidden": models.logged,
          })}
        >
          <Button.Icon
            Icon={
              !models.drawer ? <Icon.BugerMenu /> : <Icon.Close size={24} />
            }
            onclick={() => operations.setDrawer(!models.drawer)}
          />
        </div>
        <span
          className={classNames("hidden", {
            "!flex": models.logged && models.size < 720,
          })}
        >
          <NavbarLoggedMobile
            onClick={() => operations.setDrawer(!models.drawer)}
          />
        </span>
      </div>
    </header>
  );
}
