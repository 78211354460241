import { translate } from "@locales";
import { SaveSearch } from "@models/state";
import button from "@components/atoms/button";
import Icon from "@components/atoms/icon";
import { Dispatch, SetStateAction } from "react";
import classNames from "classnames";

interface Props {
  searches: string[];
  search: string;
  redirectOnClick: (value: string) => void;
  setRecentSearch: Dispatch<SetStateAction<string[]>>;
  suggestions?: string[];
}

export function useRecentSearch(props: Props) {
  const onDelete = (search: string) => {
    const filteredArray = props.searches.filter((item) => item !== search);
    props.setRecentSearch(filteredArray);
  };

  return {
    modals: {},
    operations: { onDelete },
  };
}

export default function RecentSearch(props: Props) {
  const { modals, operations } = useRecentSearch(props);

  return (
    <section
      className={classNames(
        "absolute z-10 mt-1 flex h-[246px] w-full flex-col gap-2 rounded-lg border border-[#38383a] bg-composer-textToBullets pt-5",
        { hidden: !props.searches.length && props.search.length < 1 }
      )}
    >
      <h4
        className={classNames(" p-5 text-xl text-white", {
          hidden: !props.searches.length || props.search.length > 1,
        })}
      >
        {translate("base.recent")}
      </h4>
      <ul className=" flex flex-col gap-1 overflow-y-auto text-base-grey">
        {props.search.length < 1
          ? props.searches?.map((search: string, index: number) => {
              return (
                <li
                  key={index}
                  className="flex h-11 cursor-pointer items-center justify-between p-5 hover:bg-[#1c1c1e]"
                >
                  <span
                    onClick={() => props.redirectOnClick(search)}
                    className="flex w-full items-center justify-start gap-1"
                  >
                    <Icon.Search size={16} />
                    {search}
                  </span>
                  <button.Icon
                    onclick={() => operations.onDelete(search)}
                    className="flex h-6 w-6 items-center justify-center rounded-full bg-composer-base hover:bg-memod-separator"
                    Icon={<Icon.Close className="text-base-grey" />}
                  />
                </li>
              );
            })
          : props.suggestions?.map((search: string, index: number) => {
              return (
                <li
                  key={index}
                  className="flex h-11 cursor-pointer items-center justify-between p-5 hover:bg-[#1c1c1e]"
                >
                  <span
                    onClick={() => props.redirectOnClick(search)}
                    className="flex w-full items-center justify-start gap-1"
                  >
                    <Icon.Search size={16} />
                    {search}
                  </span>
                  <button.Icon
                    className="flex h-6 w-6 items-center justify-center rounded-full bg-composer-base hover:bg-memod-separator"
                    Icon={<Icon.Close className="text-base-grey" />}
                  />
                </li>
              );
            })}
      </ul>
    </section>
  );
}
