import cookiesStorage from "@models/interactions/cookies-storage";
import MemodSDK, { WriterInterface } from "@carglassgithub/memod-sdk";

export async function fetchUrlMetaData(url: string) {
  try {
    const response = await fetch(
      `https://opengraph.io/api/1.1/site/${url}/?app_id=9ea5d5bf-e5bd-417d-97a5-4b32fe75a8bd`
    );

    return response.json();
  } catch (error) {
    return url;
  }
}

export const sdk = new MemodSDK({
  appKey: "",
  baseUrl: process.env.NEXT_PUBLIC_API as string,
  storage: cookiesStorage,
});

export const sdkv1 = new MemodSDK({
  appKey: "",
  baseUrl: process.env.NEXT_PUBLIC_API_V1 as string,
  storage: cookiesStorage,
});
