import classNames from "classnames";
import { translate } from "@locales";

interface Props {
  onLogIn?: () => void;
  onSignUp?: () => void;
  className?: string;
}

export default function NavbarLoggedOut(props: Props) {
  return (
    <>
      <button
        className={classNames(
          "rounded-full bg-memod-green-base px-3.5 py-2 hover:bg-white/20",
          props.className,
        )}
        onClick={props.onSignUp}
      >
        {translate("base.sign-up.base")}
      </button>
      <button
        className={classNames(
          "rounded-full bg-transparent px-3.5 py-2 hover:bg-white/20",
          props.className,
        )}
        onClick={props.onLogIn}
      >
        {translate("base.sign-in.loggin")}
      </button>
    </>
  );
}
