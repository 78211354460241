import { isIOS } from "react-device-detect";
import InputSearch from "@components/molecules/input-search";
import classNames from "classnames";
import Link from "next/link";
import dynamic from "next/dynamic";
import { translate } from "@locales";
import Avatar from "@components/atoms/avatar";
import { UserInterface } from "@kanvas/client-js";
import Icon from "@components/atoms/icon";

const Drawer = dynamic(
  async () => (await import("@material-tailwind/react")).Drawer,
  {
    ssr: false,
  }
);

type Props = {
  search: string;
  isHomePage?: boolean;
  autocomplete?: boolean;
  placement?: "right" | "left";
  logOut: () => void;
  setDrawer: (state: boolean) => void;
  setSearch: (state: string) => void;
  drawer: boolean;
  size: number;
  user: UserInterface | null;
};

export default function (props: Props) {
  if (props.user) {
    return (
      <Drawer
        open={props.drawer}
        placement="right"
        overlay={false}
        onClose={() => props.setDrawer(!props.drawer)}
        className={classNames(" bg-[#2C2C2E] p-4 xl:hidden")}
      >
        <div className="flex h-full flex-col justify-between p-1">
          <div className="flex h-full flex-col">
            <aside className="flex justify-end pb-4">
              <Avatar src={props.user?.photo?.url as any} size={32} />
            </aside>
            <span className="flex h-full flex-col justify-between text-xl">
              <ul className="flex w-full cursor-pointer flex-col items-start justify-start gap-4 rounded-xl text-xl text-[#D7D9DB]">
                <Link
                  href={`/create`}
                  className="flex w-full justify-start gap-2.5 border-b border-[#38383A] py-3 "
                >
                  <Icon.Plus size={16} heigth={16} className="self-center" />
                  <span>{translate("base.create")}</span>
                </Link>
                <Link
                  href={`/${props.user?.displayname}/memos`}
                  className="flex w-full justify-start gap-2.5 border-b border-[#38383A] py-3"
                >
                  <Icon.UserAvatar size={24} />
                  <span>{translate("base.profile")}</span>
                </Link>
                <Link
                  href={`/dashboard`}
                  className="flex w-full items-baseline justify-start gap-2.5 border-b border-[#38383A] py-3"
                >
                  <Icon.Analytics />
                  <span>{translate("base.library")}</span>
                </Link>
              </ul>
              <ul className="flex flex-col text-[#979797]">
                <Link
                  href={`/help`}
                  className="w-full border-b border-[#38383A] py-3"
                >
                  {translate("base.help-center")}
                </Link>

                <Link
                  href={`/settings/edit`}
                  className="flex w-full border-b border-[#38383A] py-3"
                >
                  <span>{translate("base.settings")}</span>
                </Link>
              </ul>
              <ul>
                <span className="text-[#979797]" onClick={() => props.logOut()}>
                  <span>{translate("base.sign-out")}</span>
                </span>
              </ul>
            </span>
          </div>
        </div>
      </Drawer>
    );
  }

  return (
    <Drawer
      open={props.drawer}
      placement={props.placement}
      overlay={false}
      size={5000}
      style={{ maxWidth: "500px" }}
      className={classNames(
        "bg-memod-base p-4 xl:hidden",
        {
          "top-[132px] !h-[calc(100%-132px)]":
            isIOS && props.isHomePage && props.size < 720,
          "top-[64px] !h-[calc(100%-64px)]": props.size < 720,
          "top-[72px] !h-[calc(100%-72px)]": props.size >= 720,
        },
        "md:top-[72px]"
      )}
    >
      <div className="flex h-full flex-col justify-between p-1">
        <div>
          <div className="flex flex-row">
            <InputSearch
              autocomplete={props.autocomplete}
              search={props.search}
              setSearch={props.setSearch}
              inputClassName="border-none"
            />
          </div>
          <ul className="mt-8 flex flex-col text-xl leading-8 text-memod-label">
            <li className="border-b border-memod-separator py-3">
              <Link href="/about-us">About</Link>
            </li>
            <li className="border-b border-memod-separator py-3">
              <Link href="/creators">Start Creating!</Link>
            </li>
            <li className="border-b border-memod-separator py-3">
              <a
                href="https://memod.notion.site/memod/AI-Writing-Contest-Memo-d-95fad73ac7544a4599dec184b17d7c65"
                rel="nofollow"
              >
                Memo Contest
              </a>
            </li>
          </ul>
        </div>
        <div>
          <div>
            <ul className="flex flex-col gap-4 text-white/70">
              <li>
                <Link href="/legal/end-user-license-agreement">
                  End-User License Agreement
                </Link>
              </li>
              <li>
                <Link href="/legal/terms-of-service">Terms of Service</Link>
              </li>
              <li>
                <Link href="/legal/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>

          <div className="my-5 flex flex-row items-center justify-start gap-5 text-memod-grey-base">
            <a
              href="https://www.facebook.com/memodapp1"
              rel="nofollow"
              target="_blank"
            >
              <img
                src="https://v2.memod.com/_nuxt/img/facebook.b271f80.svg"
                alt="Facebook"
                className="h-5 w-5"
                loading="lazy"
              />
            </a>
            <a
              href="http://instagram.com/memodapp"
              rel="nofollow"
              target="_blank"
              className="network"
            >
              <img
                src="https://v2.memod.com/_nuxt/img/instagram.2c54ec5.svg"
                alt="instagram"
                className="h-5 w-5"
                loading="lazy"
              />
            </a>
            <a
              href="https://twitter.com/memodapp"
              rel="nofollow"
              target="_blank"
              className="network"
            >
              <img
                src="https://v2.memod.com/_nuxt/img/twitter.8cb94e7.svg"
                alt="twitter"
                className="h-5 w-5"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/memo'd/"
              rel="nofollow"
              target="_blank"
              className="network"
            >
              <img
                src="https://v2.memod.com/_nuxt/img/linkedin.523f490.svg"
                alt="linkedin"
                className="h-5 w-5"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCvCXWvi3yJQTSxAUJjoehBQ"
              rel="nofollow"
              target="_blank"
              className="network"
            >
              <img
                src="https://v2.memod.com/_nuxt/img/youtube.aa63531.svg"
                alt="youtube"
                className="h-5 w-5"
                loading="lazy"
              />
            </a>
          </div>

          <div className="text-xs text-memod-grey-primary">
            <span>© {new Date().getFullYear()} Memo'd Technologies, LLC.</span>
            <br />
            <span>All rights reserved.</span>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
